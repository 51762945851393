import { ref } from 'vue'
import { defineStore } from 'pinia'
import { queryLocusTreeList } from '@/api'
import { CODE_ENUM } from '@/common/enum'

export const useDeviceStore = defineStore('device', () => {
  const deviceOps: any = ref([])

  const queryDeviceOps = async (params?: any) => {
    const res: any = await queryLocusTreeList(params)
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = (res?.data || []).map((item: any) => {
        const { deviceName, deviceId, deviceSn, id, ...p } = item
        return {
          label: deviceName,
          value: deviceId,
          deviceSn: deviceSn,
          id: id,
          ...p,
        }
      })
      deviceOps.value = result
      return Promise.resolve(result)
    }
  }

  return {
    deviceOps,
    queryDeviceOps,
  }
})
