import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {
  queryStrategyTriggerType,
  queryStrategySensorType,
  queryStrategyOperatorType,
} from '@/api'
import { CODE_ENUM } from '@/common/enum'
import { transformEnum } from '@/utils'

export const useWarningStore = defineStore('warning', () => {
  // 告警类别
  const triggerType: any = ref([])
  const getTriggerType = async () => {
    const res: any = await queryStrategyTriggerType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      // 目前天数还没有兼容水利项目的等级告警
      const result = transformEnum(res?.data || []).filter(
        (item: any) => item.value !== 'level'
      )
      triggerType.value = result
      return Promise.resolve(result)
    }
  }

  // 传感器指标类型
  const sensorType: any = ref([])
  const getSensorType = async () => {
    const res: any = await queryStrategySensorType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      sensorType.value = result
      return Promise.resolve(result)
    }
  }

  // 运算符类型
  const operatorType: any = ref([])
  const getOperatorType = async () => {
    const res: any = await queryStrategyOperatorType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      operatorType.value = result
      return Promise.resolve(result)
    }
  }

  return {
    triggerType,
    getTriggerType,
    sensorType,
    getSensorType,
    operatorType,
    getOperatorType,
  }
})
